import find from 'lodash/find';
import { compile } from 'path-to-regexp';
import { matchPath } from 'react-router-dom';

import { stringify } from './urlHelpers';
import routeConfiguration, { RouteConfiguration, RouteName } from 'routing/routeConfiguration';

// NOTE: This file imports urlHelpers.js, which may lead to circular dependency

export const getRoute = (nameToFind: RouteName) => {
  const route = find(routeConfiguration, route => route.name === nameToFind);
  if (!route) {
    throw new Error(`Component "${nameToFind}" was not found.`);
  }
  return route;
};

/**
 * Shorthand for single path call. (```getPath('ListingPage',  { id: 'listingId' });```)
 *
 * '/l/:id' -> '/l/listingId'
 * '/l/:slug/:id' -> '/l/slugX/listingId'
 */
export const getPath = (nameToFind: RouteName, params: Record<string, any> = {}) => {
  try {
    const hasEmptySlug = params && params.hasOwnProperty('slug') && params.slug === '';
    const hasNoSlug = !params.hasOwnProperty('slug');
    const slug = hasEmptySlug || hasNoSlug ? 'no-slug' : params.slug;

    const path = getRoute(nameToFind).path;
    const pathParams = { ...params, slug };

    return compile(path)(pathParams);
  } catch (error) {
    console.error('Error in getPath', error);
    throw error;
  }
};

/**
 * Like getPath, but with search params and hash.
 *
 * In contrast to absolute URLs, relative URLs don't contain protocol, host, or port.
 */
export const getRelativeUrl = (
  name: RouteName,
  {
    params = {},
    search = {},
    hash = '',
  }: {
    params?: Record<string, any>;
    search?: any;
    hash?: string;
  }
) => {
  const searchQuery = stringify(search);
  const includeSearchQuery = searchQuery.length > 0 ? `?${searchQuery}` : '';
  const path = getPath(name, params);

  return `${path}${includeSearchQuery}${hash}`;
};

/**
 * Find the matching routes and their params for the given pathname
 *
 * @param {String} pathname - Full URL path from root with possible
 * search params and hash included
 *
 * @return {Array<{ route, params }>} - All matches as { route, params } objects if matches has
 * exact flag set to false. If not, an array containing just the first matched exact route is returned.
 */
export const matchPathname = (
  pathname: string
): { route: RouteConfiguration[number]; params: Record<string, string> }[] => {
  const matchedRoutes = (routeConfiguration as any).reduce((matches, route) => {
    const { path, exact = true } = route;
    const match = matchPath(pathname, { path, exact });
    if (match) {
      matches.push({
        route,
        params: match.params || {},
      });
    }
    return matches;
  }, []);

  const matchedExactRoute = matchedRoutes.find(r => {
    return r.exact === true || r.exact == null;
  });

  // We return matched 'exact' path route only if such exists
  // and all matches if no exact flag exists.
  return matchedExactRoute ? [matchedExactRoute] : matchedRoutes;
};

/**
 * Get the canonical URL from the given location
 *
 * @param {Array<{ route }>} routes - Route configuration as flat array
 * @param {Object} location - location object from React Router
 *
 * @return {String} Canonical URL of the given location
 *
 */
export const canonicalRoutePath = (location, pathOnly = false) => {
  const { pathname, search, hash } = location;

  const matches = matchPathname(pathname);
  const isListingRoute = matches.length === 1 && matches[0].route.name === 'ListingPage';

  if (isListingRoute) {
    // Remove the dynamic slug from the listing page canonical URL

    // Remove possible trailing slash
    const cleanedPathName = pathname.replace(/\/$/, '');
    const parts = cleanedPathName.split('/');

    if (parts.length !== 4) {
      throw new Error('Expected ListingPage route to have 4 parts');
    }
    const canonicalListingPathname = `/${parts[1]}/${parts[3]}`;
    return pathOnly ? canonicalListingPathname : `${canonicalListingPathname}${search}${hash}`;
  }

  return pathOnly ? pathname : `${pathname}${search}${hash}`;
};
