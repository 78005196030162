import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { PiImageSquareThin } from 'react-icons/pi';
import { useHistory, useLocation } from 'react-router-dom';

import BrandCombobox from './components/BrandCombobox/BrandCombobox';
import { useCreatePosting } from './PostingsPage.hooks';
import { IconClose } from 'assets/icons';
import { Button, NamedRedirect, PrimaryButton } from 'components';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from 'components/_chadcn/Dialog';
import { TextInput } from 'components/FieldTextInputV2/FieldTextInput';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';

function PostingCreateModal({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [brandName, setBrandName] = useState('');
  const [brandId, setBrandId] = useState<number | null>(null);
  const [size, setSize] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const { mutateAsync: createPosting, isLoading } = useCreatePosting();
  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!description || !image) {
      alert('Please fill out all required fields.');
      return;
    }

    try {
      const postingData = {
        description,
        size,
        image,
        ...(brandId ? { brandId } : {}),
      };

      const result = await createPosting(postingData);
      setOpen(false);
      history.push(`/postings?postingId=${result.id}`);
      toast.success('Posting created. Background will be removed shortly.');
    } catch (error) {
      console.error('Error creating posting:', error);
      alert('Failed to create posting. Please try again.');
    }
  };

  const location = useLocation();
  const { currentUser, isLoading: isCurrentUserLoading } = useCurrentUser();

  const handleOpenChange = useCallback((open: boolean) => {
    setOpen(open);
    if (!open) {
      setDescription('');
      setBrandName('');
      setBrandId(null);
      setSize('');
      setImage(null);
    }
  }, []);

  if (!isCurrentUserLoading && !currentUser?.id && open) {
    return <NamedRedirect name="LoginPage" state={{ from: location.pathname }} />;
  }

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="min-h-full sm:min-h-[initial] max-h-[100vh] sm:max-h-[90vh] overflow-auto sm:h-auto w-full max-w-[100vw] sm:max-w-lg">
        <DialogTitle className="text-center text-2">I'm #lookingfor</DialogTitle>
        <form onSubmit={handleSubmit} className="space-y-4 overflow-hidden">
          {!image && (
            <label className="cursor-pointer transition-shadow active:shadow-lg mx-auto text-type-dark flex flex-col items-center justify-center gap-1 border border-border h-[200px] aspect-[3/4] rounded-xl">
              <PiImageSquareThin size={40} />
              <span className="font-syne uppercase font-bold text-00">+ Add photo</span>
              <span className="text-type-subtle text-00 text-center font-normal">
                We'll remove the background
              </span>
              <input
                type="file"
                id="images"
                accept="image/*"
                onChange={e => setImage(e.target.files?.[0] || null)}
                required
                hidden
              />
            </label>
          )}
          {image && (
            <div className="relative">
              <div className="flex justify-center">
                <img
                  src={URL.createObjectURL(image)}
                  alt="Preview"
                  className="h-[200px] object-contain"
                />
              </div>
              <button
                className="absolute right-0 top-1 p-1 bg-black/70 backdrop-blur-sm text-white rounded-full"
                onClick={() => setImage(null)}
              >
                <IconClose />
              </button>
            </div>
          )}
          <div className="grid gap-2">
            <TextInput
              id="description"
              name="description"
              label="Description"
              placeholder="Describe your item"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            <div>
              <label htmlFor="brandId">Brand</label>
              <BrandCombobox
                value={brandName}
                onChange={setBrandName}
                onBrandSelect={brand => {
                  setBrandId(brand.id);
                  setBrandName(brand.name);
                }}
              />
            </div>
            <TextInput
              id="size"
              name="size"
              label="Size"
              placeholder="Size"
              value={size}
              onChange={e => setSize(e.target.value)}
            />
          </div>
          <div className="flex gap-2">
            <DialogClose asChild>
              <Button className="flex-1">Cancel</Button>
            </DialogClose>
            <PrimaryButton
              className="flex-1"
              type="submit"
              inProgress={isLoading}
              disabled={isLoading}
            >
              Post
            </PrimaryButton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default PostingCreateModal;
