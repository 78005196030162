/* eslint-disable react-hooks/rules-of-hooks */
import loadable from '@loadable/component';
import { QueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';
import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import PreviewResolverPage from '../containers/PreviewResolverPage/PreviewResolverPage';
import { getSEOPagesListingQueryParams } from '../containers/SEOPages/SEOPages';
import { types as sdkTypes } from '../util/sdkLoader';
import CheckoutPageGuest from 'containers/CheckoutPage/CheckoutPageGuest';
import {
  getEditablePageKey,
  getEditablePageQueryFn,
} from 'containers/EditablePages/EditablePages.hooks';
import PostingsPage from 'containers/PostingsPage/PostingsPage';
import { parseFiltersQueryParams } from 'containers/SearchPage/SearchPage.hooks';
import ThankYouPage from 'containers/ThankYouPage/ThankYouPage';
import { getListingQueryFn, getListingQueryKey } from 'hooks/api/listings/useGetListing';
import { getListingsQueryFn, getListingsQueryKey } from 'hooks/api/listings/useGetListings';
import { getConfigurationQueryFn, getConfigurationQueryKey } from 'hooks/api/useGetConfiguration';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { getIpApiUrl } from 'util/constants';

const { UUID } = sdkTypes;

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AuthenticationPage = loadable(
  () =>
    import(
      /* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'
    )
);
const CheckoutPage = loadable(
  () => import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage')
);
const CMSPage = loadable(
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  () => import(/* webpackChunkName: "CMSPage" */ '../containers/CMSPage/CMSPage')
);
const EditListingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'
    )
);
const EmailVerificationPage = loadable(
  () =>
    import(
      /* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'
    )
);
const ChatPage = loadable(
  () => import(/* webpackChunkName: "ChatPage" */ '../containers/ChatPage/ChatPage')
);
const ListingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPage'
    )
);
const ManageListingsPage = loadable(
  () =>
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    import(
      /* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'
    )
);
const PasswordRecoveryPage = loadable(
  () =>
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    import(
      /* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'
    )
);
const PasswordResetPage = loadable(
  () =>
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    import(
      /* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'
    )
);
const ProfilePage = loadable(
  () => import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage')
);

const SettingsPage = loadable(
  () => import(/* webpackChunkName: "SettingsPage" */ '../containers/SettingsPage/SettingsPage')
);

const InfoPage = loadable(
  () => import(/* webpackChunkName: "InfoPage" */ '../containers/InfoPage/InfoPage')
);

const LegalPage = loadable(
  () => import(/* webpackChunkName: "LegalPage" */ '../containers/LegalPage/LegalPage')
);

const SearchPage = loadable(
  () =>
    import(
      /* webpackChunkName: "SearchPageWithGrid" */ /* webpackPrefetch: true */ '../containers/SearchPage/SearchPage'
    )
);
const ResellLandingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ResellLandingPage" */ /* webpackPrefetch: true */ '../containers/ResellLandingPage/ResellLandingPage'
    )
);

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(
  () =>
    import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage')
);

const CustomLandingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CustomLandingPage" */ '../containers/CustomLandingPage/CustomLandingPage'
    )
);

const ByFarLandingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ByFarLandingPage" */ '../containers/ByFarLandingPage/ByFarLandingPage'
    )
);

const PiferiLandingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "PiferiLandingPage" */ '../containers/PiferiLandingPage/PiferiLandingPage'
    )
);

const WimbledonGalleryPage = loadable(
  () =>
    import(
      /* webpackChunkName: "WimbledonGalleryPage" */ '../containers/WimbledonGalleryPage/WimbledonGalleryPage'
    )
);

const MobileScannerPage = loadable(
  () =>
    import(
      /* webpackChunkName: "MobileScannerPage" */ '../containers/MobileScannerPage/MobileScannerPage'
    )
);

const WalletPage = loadable(
  () => import(/* webpackChunkName: "WalletPage" */ '../containers/WalletPage/WalletPage')
);

const DeveloperPage = loadable(
  () => import(/* webpackChunkName: "DeveloperPage" */ '../containers/DeveloperPage/DeveloperPage')
);

const VintageClothingPage = loadable(
  () =>
    import(
      /* webpackChunkName: "VintageClothingPage" */ '../containers/VintageClothingPage/VintageClothingPage'
    )
);

const EditablePages = loadable(
  () =>
    import(
      /* webpackChunkName: "EditablePages" */ /* webpackPrefetch: true */ '../containers/EditablePages/EditablePages'
    )
);
const SEOPages = loadable(
  () =>
    import(
      /* webpackChunkName: "SEOPages" */ /* webpackPrefetch: true */ '../containers/SEOPages/SEOPages'
    )
);

export const ACCOUNT_SETTINGS_PAGES = [
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'ProfileAddressesPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

const lockConfig = {
  auth: false,
  authPage: 'WelcomePage',
};

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = [
  {
    path: '/',
    name: 'LandingPage',
    component: CustomLandingPage,
  },
  {
    path: '/pages/:pageId',
    name: 'EditablePages',
    component: EditablePages,
    prefetchQueryData: (params, search, config, queryClient: QueryClient, sdk) => {
      return queryClient.fetchQuery(
        getEditablePageKey(params.pageId),
        getEditablePageQueryFn(params.pageId)
      );
    },
  },
  {
    path: '/pages',
    name: 'EditablePages',
    component: () => <NamedRedirect name="EditablePages" params={{ pageId: 'list' }} />,
  },
  {
    path: '/by-far',
    name: 'ByFarLandingPage',
    component: ByFarLandingPage,
  },
  {
    path: '/piferi',
    name: 'PiferiLandingPage',
    component: PiferiLandingPage,
  },
  {
    path: '/wimbledon-party',
    name: 'WimbledonGalleryPage',
    component: WimbledonGalleryPage,
  },
  {
    path: '/p/:pageId',
    name: 'CMSPage',
    component: CMSPage,
    loadData: pageDataLoadingAPI.CMSPage.loadData,
  },
  {
    path: '/shop',
    name: 'SearchPage',
    component: SearchPage,
    ...lockConfig,
    prefetchQueryData: (params, search, config, queryClient: QueryClient) => {
      const filtersParams = parseFiltersQueryParams(search);

      return queryClient.fetchQuery(
        getListingsQueryKey(filtersParams),
        getListingsQueryFn(filtersParams)
      );
    },
  },
  {
    path: '/s',
    name: 'SearchPageLegacy',
    component: () => {
      const location = useLocation();
      return <NamedRedirect name="SearchPage" search={location.search} />;
    },
  },
  {
    path: '/l',
    name: 'ListingBasePage',
    component: RedirectToLandingPage,
    ...lockConfig,
  },
  {
    path: '/l/:slug/:id',
    name: 'ListingPage',
    component: ListingPage,
    ...lockConfig,
    prefetchQueryData: async (params, search, config, queryClient: QueryClient, sdk, requestIp) => {
      const listingId = new UUID(params.id);

      const country = await fetch(getIpApiUrl(requestIp))
        .then(res => res.json())
        .then(data => data.country || 'GB')
        .catch(err => {
          console.error('Error fetching country', err);
          return 'GB';
        });

      return await queryClient.fetchQuery(
        getListingQueryKey(listingId, { isOwn: false, country }),
        () => getListingQueryFn(listingId, { isOwn: false, country })
      );
    },
  },
  {
    path: '/l/:slug/:id/checkout',
    name: 'CheckoutPage',
    auth: true,
    component: CheckoutPage,
  },
  {
    path: '/l/:slug/:id/checkout/guest',
    name: 'CheckoutPageGuest',
    auth: false,
    component: CheckoutPageGuest,
  },
  {
    path: '/l/:slug/:id/:variant',
    name: 'ListingPageVariant',
    auth: true,
    authPage: 'LoginPage',
    component: ListingPage,
  },
  {
    path: '/l/new',
    name: 'NewListingPage',
    auth: true,
    component: () => {
      const location = useLocation();

      return (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'photos' }}
          search={location.search}
        />
      );
    },
  },
  {
    path: '/l/:slug/:id/:type/:tab',
    name: 'EditListingPage',
    auth: true,
    component: EditListingPage,
  },

  // Canonical path should be after the `/l/new` path since they
  // conflict and `new` is not a valid listing UUID.
  {
    path: '/l/:id',
    name: 'ListingPageCanonical',
    component: ListingPage,
    ...lockConfig,
  },
  {
    path: '/u',
    name: 'ProfileBasePage',
    component: RedirectToLandingPage,
    ...lockConfig,
  },
  {
    path: '/u/me',
    name: 'MyProfilePage',
    auth: true,
    authPage: 'LoginPage',
    component: () => {
      const { currentUser } = useCurrentUser();
      const location = useLocation();

      return currentUser ? (
        <NamedRedirect
          name="ProfilePage"
          params={{ id: currentUser.id?.uuid }}
          search={location.search}
        />
      ) : null;
    },
  },
  {
    path: '/c/:path*',
    name: 'SEOPages',
    component: SEOPages,
    prefetchQueryData: async (params, search, config, queryClient: QueryClient) => {
      const configuration = await queryClient.fetchQuery(
        getConfigurationQueryKey(),
        getConfigurationQueryFn()
      );
      const customData = configuration?.SEOMetadata?.find(
        p => p.urlPathname.toLowerCase() === `/c/${params.path?.toLowerCase()}`
      )?.customData;

      if (!customData) {
        return;
      }

      const queryParams = getSEOPagesListingQueryParams(customData);
      return Promise.all([
        queryClient.fetchInfiniteQuery(getListingsQueryKey({}), getListingsQueryFn({})),
        queryClient.fetchInfiniteQuery(
          getListingsQueryKey(queryParams),
          getListingsQueryFn(queryParams)
        ),
      ]);
    },
  },
  {
    path: '/u/:id',
    name: 'ProfilePage',
    component: ProfilePage,
    loadData: pageDataLoadingAPI.ProfilePage.loadData,
    ...lockConfig,
  },
  {
    path: '/user/:handle',
    name: 'ProfilePageWithHandle',
    component: ProfilePage,
    loadData: pageDataLoadingAPI.ProfilePage.loadData,
    ...lockConfig,
  },
  {
    path: '/wallet',
    name: 'WalletPage',
    auth: true,
    authPage: 'LoginPage',
    component: WalletPage,
  },
  {
    path: '/settings',
    name: 'SettingsPageRoot',
    auth: true,
    authPage: 'LoginPage',
    component: SettingsPage,
  },
  {
    path: '/settings/:tab',
    name: 'SettingsPage',
    auth: true,
    authPage: 'LoginPage',
    component: SettingsPage,
  },
  {
    path: '/info/:tab',
    name: 'InfoPage',
    component: InfoPage,
  },
  {
    path: '/info',
    name: 'InfoPageRoot',
    component: InfoPage,
  },
  {
    path: '/legal/:tab',
    name: 'LegalPage',
    component: LegalPage,
  },
  {
    path: '/legal',
    name: 'LegalPageRoot',
    component: LegalPage,
  },
  {
    path: '/thank-you',
    name: 'ThankYouPage',
    component: ThankYouPage,
  },
  // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
  // so that in the error case users can be redirected back to the LoginPage
  // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
  {
    path: '/login',
    name: 'LoginPage',
    component: AuthenticationPage,
    extraProps: { tab: 'login' },
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: AuthenticationPage,
    extraProps: { tab: 'signup' },
    loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
  },
  {
    path: '/welcome',
    name: 'WelcomePage',
    component: AuthenticationPage,
    extraProps: { tab: 'welcome' },
  },
  {
    path: '/confirm',
    name: 'ConfirmPage',
    component: AuthenticationPage,
    extraProps: { tab: 'confirm' },
  },
  {
    path: '/recover-password',
    name: 'PasswordRecoveryPage',
    component: PasswordRecoveryPage,
  },
  {
    path: '/chat',
    name: 'ChatPage',
    auth: true,
    authPage: 'LoginPage',
    component: ChatPage,
  },
  {
    path: '/listings',
    name: 'ManageListingsPage',
    auth: true,
    authPage: 'LoginPage',
    component: ManageListingsPage,
    loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
  },
  {
    path: '/postings',
    name: 'PostingsPage',
    component: PostingsPage,
  },
  {
    path: '/resell',
    name: 'ResellLandingPage',
    auth: true,
    authPage: 'LoginPage',
    component: ResellLandingPage,
  },
  {
    path: '/mobile-scanner',
    name: 'MobileScannerPage',
    component: MobileScannerPage,
  },
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: StyleguidePage,
  },
  {
    path: '/styleguide/g/:group',
    name: 'StyleguideGroup',
    component: StyleguidePage,
  },
  {
    path: '/styleguide/c/:component',
    name: 'StyleguideComponent',
    component: StyleguidePage,
  },
  {
    path: '/styleguide/c/:component/:example',
    name: 'StyleguideComponentExample',
    component: StyleguidePage,
  },
  {
    path: '/styleguide/c/:component/:example/raw',
    name: 'StyleguideComponentExampleRaw',
    component: StyleguidePage,
    extraProps: { raw: true },
  },
  {
    path: '/notfound',
    name: 'NotFoundPage',
    component: props => <NotFoundPage {...props} />,
  },
  {
    path: '/dev',
    name: 'DeveloperPage',
    component: DeveloperPage,
  },
  {
    path: '/vintage/clothing',
    name: 'VintageClothingPage',
    component: VintageClothingPage,
  },
  // Do not change this path!
  //
  // The API expects that the application implements /reset-password endpoint
  {
    path: '/reset-password',
    name: 'PasswordResetPage',
    component: PasswordResetPage,
  },

  // Do not change this path!
  //
  // The API expects that the application implements /verify-email endpoint
  {
    path: '/verify-email',
    name: 'EmailVerificationPage',
    auth: true,
    authPage: 'LoginPage',
    component: EmailVerificationPage,
  },
  // Do not change this path!
  //
  // The API expects that the application implements /preview endpoint
  {
    path: '/preview',
    name: 'PreviewResolverPage',
    component: PreviewResolverPage,
  },
] as const;

export type RouteConfiguration = typeof routeConfiguration;
export type RouteName = RouteConfiguration[number]['name'];
export type Route = RouteConfiguration[number] & {
  authPage?: RouteName;
  extraProps?: object;
};

export default routeConfiguration;
