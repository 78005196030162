import { PropsOf } from '@emotion/react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import * as Portal from '@radix-ui/react-portal';
import { captureException, withScope } from '@sentry/react';
import {
  Hydrate,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import Cookies from 'js-cookie';
import difference from 'lodash/difference';
import mapValues from 'lodash/mapValues';
import React, { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { HelmetProvider } from 'react-helmet-async';
import toast, { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import { MaintenanceMode } from './components';
import defaultConfig from './config/configDefault';
import appSettings from './config/settings';
import { ConfigurationProvider } from './context/configurationContext';
import Routes from './routing/Routes';
import configureStore from './store';
import defaultMessages from './translations/defaultMicrocopy.json';
import { mergeConfig } from './util/configHelpers';
import { IntlProvider } from './util/reactIntl';
import { IntercomChatLauncher } from 'components';
import MetaTagGenerator from 'components/MetaTagGenerator/MetaTagGenerator';
import { GROWTHBOOK_API_HOST, GROWTHBOOK_CLIENT_KEY, INTERCOM_APP_ID, NODE_ENV } from 'config/env';
// Sharetribe Web Template uses English translations as default translations.
import { SdkProvider } from 'util/sdkContext';

// If you want to change the language of default (fallback) translations,
// change the imports to match the wanted locale:
//
//   1) Change the language in the config.js file!
//   2) Import correct locale rules for Moment library
//   3) Use the `messagesInLocale` import to add the correct translation file.
//   4) (optionally) To support older browsers you need add the intl-relativetimeformat npm packages
//      and take it into use in `util/polyfills.js`

// Note that there is also translations in './translations/countryCodes.js' file
// This file contains ISO 3166-1 alpha-2 country codes, country names and their translations in our default languages
// This used to collect billing address in StripePaymentAddress on CheckoutPage

// Step 2:
// If you are using a non-english locale with moment library,
// you should also import time specific formatting rules for that locale
// There are 2 ways to do it:
// - you can add your preferred locale to MomentLocaleLoader or
// - stop using MomentLocaleLoader component and directly import the locale here.
// E.g. for French:
// import 'moment/locale/fr';
// const hardCodedLocale = NODE_ENV === 'test' ? 'en' : 'fr';

// Step 3:
// The "./translations/defaultMicrocopy.json" has generic English translations
// that should work as a default translation if some translation keys are missing
// from the hosted translation.json (which can be edited in Console). The other files
// (e.g. en.json) in that directory has Biketribe themed translations.
//
// If you are using a non-english locale, point `messagesInLocale` to correct <lang>.json file.
// That way the priority order would be:
//   1. hosted translation.json
//   2. <lang>.json
//   3. defaultMicrocopy.json
//
// I.e. remove "const messagesInLocale" and add import for the correct locale:
// import messagesInLocale from './translations/fr.json';
//
// However, the recommendation is that you translate the defaultMicrocopy.json file and keep it updated.
// That way you can avoid importing <lang>.json into build files, which is better for performance.
const messagesInLocale = {};

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const addMissingTranslations = (
  sourceLangTranslations: Record<string, string>,
  targetLangTranslations: Record<string, string>
) => {
  const sourceKeys = Object.keys(sourceLangTranslations);
  const targetKeys = Object.keys(targetLangTranslations);

  // if there's no translations defined for target language, return source translations
  if (targetKeys.length === 0) {
    return sourceLangTranslations;
  }
  const missingKeys = difference(sourceKeys, targetKeys);

  const addMissingTranslation = (translations: Record<string, string>, missingKey: string) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  });

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
};

// Get default messages for a given locale.
//
// Note: Locale should not affect the tests. We ensure this by providing
//       messages with the key as the value of each message and discard the value.
//       { 'My.translationKey1': 'My.translationKey1', 'My.translationKey2': 'My.translationKey2' }
const isTestEnv = NODE_ENV === 'test';
const localeMessages = isTestEnv
  ? mapValues(defaultMessages, (val, key) => key)
  : addMissingTranslations(defaultMessages, messagesInLocale);

const Configurations: React.FC<{
  appConfig: any;
}> = props => {
  const { appConfig, children } = props;

  return <ConfigurationProvider value={appConfig}>{children}</ConfigurationProvider>;
};

const MaintenanceModeError: React.FC<{
  locale: string;
  messages: Record<string, string>;
  helmetContext?: any;
}> = props => {
  const { locale, messages, helmetContext } = props;
  return (
    <IntlProvider locale={locale} messages={messages} textComponent="span">
      <HelmetProvider context={helmetContext}>
        <MaintenanceMode />
      </HelmetProvider>
    </IntlProvider>
  );
};

export function handleQueryError(error, context?: any) {
  const backendErrorMessage = error.response?.data?.errors?.[0]?.displayMessage;
  const errorMessage =
    backendErrorMessage || context?.meta?.errorMessage || (typeof error === 'string' && error);
  if (errorMessage) {
    toast.error(errorMessage);
  }
}

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (err: any, _variables, _context, mutation) => {
      withScope(scope => {
        scope.setContext('mutation', {
          mutationName: mutation.meta?.name,
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });

        if (mutation.meta?.name) {
          scope.setTag('mutation', mutation.meta.name as string);
          if (err.message) {
            err.message = `${mutation.meta.name} - ${err.message}`;
          }
        }

        const fingerprint = [
          ...(mutation.meta?.name ? [mutation.meta.name as string] : []),
          // Duplicate to prevent modification
          ...(Array.from(mutation.options.mutationKey || []) as string[]),
        ];
        if (fingerprint.length) {
          scope.setFingerprint(fingerprint);
        }

        captureException(err);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      withScope(scope => {
        scope.setContext('query', { queryHash: query.queryHash });
        scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, '0')]);
        captureException(err);
      });
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const intercomProps = {
  appId: INTERCOM_APP_ID,
  autoBoot: true,
  autoBootProps: {
    hideDefaultLauncher: true,
  },
};

const gb = new GrowthBook({
  apiHost: GROWTHBOOK_API_HOST,
  clientKey: GROWTHBOOK_CLIENT_KEY,
  trackingCallback: (experiment, result) => {
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
  attributes: { id: Cookies.get('growthbook_user_id') },
});

export const ClientApp: React.FC<{
  store: any;
  hostedTranslations?: Record<string, string>;
  hostedConfig?: Record<string, any>;
}> = props => {
  const { store, hostedTranslations = {}, hostedConfig = {} } = props;
  const appConfig = mergeConfig(hostedConfig, defaultConfig);

  const gbPayload = JSON.parse(window.__GROWTHBOOK_PAYLOAD__ || '{}');
  const growthbook = useMemo(
    () =>
      gb.initSync({
        payload: gbPayload,
        // Optional, enable streaming updates
        streaming: true,
      }),
    [gbPayload]
  );

  const metaConfig = JSON.parse(window.__METADATA_PAYLOAD__ || '{}');

  // Show MaintenanceMode if the mandatory configurations are not available
  if (!appConfig.hasMandatoryConfigurations) {
    return (
      <MaintenanceModeError
        locale={appConfig.localization.locale}
        messages={{ ...hostedTranslations, ...localeMessages }}
      />
    );
  }

  // Marketplace color and branding image comes from configs
  // If set, we need to create CSS Property and set it to DOM (documentElement is selected here)
  // This provides marketplace color for everything under <html> tag (including modals/portals)
  // Note: This is also set on Page component to provide server-side rendering.
  const elem = window.document.documentElement;
  if (appConfig.branding.marketplaceColor) {
    elem.style.setProperty('--marketplaceColor', appConfig.branding.marketplaceColor);
    elem.style.setProperty('--marketplaceColorDark', appConfig.branding.marketplaceColorDark);
    elem.style.setProperty('--marketplaceColorLight', appConfig.branding.marketplaceColorLight);
  }
  // This gives good input for debugging issues on live environments, but with test it's not needed.
  const logLoadDataCalls = appSettings?.env !== 'test';

  return (
    <Configurations appConfig={appConfig}>
      <IntlProvider
        locale={appConfig.localization.locale}
        messages={{ ...hostedTranslations, ...localeMessages }}
        textComponent="span"
      >
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={window.__REACT_QUERY_STATE__}>
              <GrowthBookProvider growthbook={growthbook}>
                <HelmetProvider>
                  <IntercomProvider {...intercomProps}>
                    <IntercomChatLauncher />
                    <BrowserRouter>
                      <Portal.Root container={document.getElementById('toast-root')}>
                        <Toaster />
                      </Portal.Root>
                      <MetaTagGenerator metaConfig={metaConfig} />
                      <Routes logLoadDataCalls={logLoadDataCalls} />
                    </BrowserRouter>
                  </IntercomProvider>
                </HelmetProvider>
              </GrowthBookProvider>
            </Hydrate>
          </QueryClientProvider>
        </Provider>
      </IntlProvider>
    </Configurations>
  );
};

export const ServerApp: React.FC<{
  url: string;
  context: any;
  store: any;
  helmetContext?: PropsOf<typeof HelmetProvider>['context'];
  hostedTranslations?: Record<string, string>;
  hostedConfig?: Record<string, any>;
  dehydratedState?: any;
  gbPayload?: any;
  metaConfig?: any;
}> = props => {
  const {
    url,
    context,
    helmetContext,
    store,
    hostedTranslations = {},
    hostedConfig = {},
    gbPayload,
    metaConfig,
  } = props;
  const appConfig = mergeConfig(hostedConfig, defaultConfig);
  HelmetProvider.canUseDOM = false;

  const growthbook = useMemo(
    () =>
      gb.initSync({
        payload: gbPayload,
        // Optional, enable streaming updates
        streaming: true,
      }),
    [gbPayload]
  );

  // Show MaintenanceMode if the mandatory configurations are not available
  if (!appConfig.hasMandatoryConfigurations) {
    return (
      <MaintenanceModeError
        locale={appConfig.localization.locale}
        messages={{ ...hostedTranslations, ...localeMessages }}
        helmetContext={helmetContext}
      />
    );
  }

  return (
    <Configurations appConfig={appConfig}>
      <IntlProvider
        locale={appConfig.localization.locale}
        messages={{ ...hostedTranslations, ...localeMessages }}
        textComponent="span"
      >
        {/* We're preloading the data so we won't be making sdk calls from the components */}
        <SdkProvider value="DUMMY SDK">
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={props.dehydratedState}>
                <GrowthBookProvider growthbook={growthbook}>
                  <HelmetProvider context={helmetContext}>
                    <IntercomProvider {...intercomProps}>
                      <IntercomChatLauncher />
                      <StaticRouter location={url} context={context}>
                        <MetaTagGenerator metaConfig={metaConfig} />
                        <Toaster />
                        <Routes />
                      </StaticRouter>
                    </IntercomProvider>
                  </HelmetProvider>
                </GrowthBookProvider>
              </Hydrate>
            </QueryClientProvider>
          </Provider>
        </SdkProvider>
      </IntlProvider>
    </Configurations>
  );
};

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (
  url: string,
  serverContext: any,
  preloadedState: any,
  hostedTranslations: Record<string, string>,
  hostedConfig: Record<string, any>,
  collectChunks: any,
  dehydratedState: any,
  gbPayload: any,
  metaConfig: any
) => {
  // Don't pass an SDK instance since we're only rendering the
  // component tree with the preloaded store state and components
  // shouldn't do any SDK calls in the (server) rendering lifecycle.
  const store = configureStore(preloadedState);

  // Helmet types are not up to date, so we need to use any here 🤷
  const helmetContext = {} as any;

  // When rendering the app on server, we wrap the app with webExtractor.collectChunks
  // This is needed to figure out correct chunks/scripts to be included to server-rendered page.
  // https://loadable-components.com/docs/server-side-rendering/#3-setup-chunkextractor-server-side
  const WithChunks = collectChunks(
    <ServerApp
      url={url}
      context={serverContext}
      helmetContext={helmetContext}
      store={store}
      hostedTranslations={hostedTranslations}
      hostedConfig={hostedConfig}
      dehydratedState={dehydratedState}
      gbPayload={gbPayload}
      metaConfig={metaConfig}
    />
  );
  const body = ReactDOMServer.renderToString(WithChunks);
  const { helmet: head } = helmetContext;

  return { head, body };
};
